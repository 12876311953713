import React from "react" ;

export default function Side1() {
    return (
        <div className="infoContent infoContent1">
    <div>
        <h1>L</h1>
        <h2>Lomalaskuri</h2>
        <p>2023</p>
    </div>
    </div>
    );
};